































































































import { Vue, Component } from 'vue-property-decorator';
import { I18n } from '@aws-amplify/core';
import { Storage } from '@aws-amplify/storage';
import { mdiPencil, mdiAccountCircle } from '@mdi/js';
import mime from 'mime-types';
import imageCompression from 'browser-image-compression';
import type { UserState } from '../plugins/store/user';
import { userStore } from '../plugins/store';
import { Locale, Translations, supportedLanguages } from '../plugins/i18n';
import { noop } from 'lodash';
import type { ValidationRules } from '../typings/field';

@Component
export default class AccountView extends Vue {
  private readonly defaultAvatar: string =
    'data:image/svg+xml;base64,' +
    btoa(
      `<svg xmlns="http://www.w3.org/2000/svg" viewBox="2 2 20 20"><path fill="#6F6F6F" d="${mdiAccountCircle}"></path></svg>`
    );
  private readonly svgPath: string = mdiPencil;
  private readonly nameRules: ValidationRules = [
    (value: unknown): boolean | string =>
      !!value || I18n.get(Translations.REQUIRED)
  ];
  private isSaving: boolean = false;
  private valid: boolean = false;
  private imageFile: File | null = null;
  private hasError: boolean = false;
  private nameModel: string | null = null;
  private localeModel: string | null = null;
  private avatarModel: string | null = null;

  private get supportedLanguages(): Array<{
    value: keyof typeof Locale;
    text: string;
  }> {
    return userStore.locale
      ? supportedLanguages.map((locale: keyof typeof Locale): {
          value: keyof typeof Locale;
          text: string;
        } => ({
          value: locale,
          text: I18n.get(Translations[locale])
        }))
      : [];
  }

  private get email(): string {
    return userStore.email;
  }

  private get name(): string {
    return userStore.name;
  }

  private set name(name: string) {
    if (name === userStore.name) {
      this.nameModel = null;
    } else {
      this.nameModel = name;
    }
  }

  private get locale(): string {
    return userStore.locale as string;
  }

  private set locale(locale: string) {
    if (locale === userStore.locale) {
      this.localeModel = null;
    } else {
      this.localeModel = locale;
    }
  }

  private get avatarUrl(): string {
    if (this.avatarModel) {
      return this.avatarModel;
    } else {
      return userStore.avatarUrl;
    }
  }

  private get dirty(): boolean {
    return (
      this.nameModel !== null ||
      this.localeModel !== null ||
      this.imageFile !== null
    );
  }

  private pickImage(event: Event): void {
    const file: File = ((event.target as HTMLInputElement).files || [])[0];
    if (!file) {
      return;
    }
    imageCompression
      .getDataUrlFromFile(file)
      .then((url: string): void => {
        this.avatarModel = url;
      })
      .then(
        (): Promise<File> =>
          imageCompression(file, {
            maxSizeMB: 0.5,
            maxWidthOrHeight: 512,
            useWebWorker: true,
            maxIteration: 10
          })
      )
      .then((file: File): void => {
        this.imageFile = file;
      });
  }

  private saveChanges(): void {
    this.hasError = false;
    this.isSaving = true;
    const changes: Partial<UserState> = {};
    const promises: Promise<void>[] = [];
    if (this.nameModel !== null) {
      changes.name = this.nameModel;
    }
    if (this.localeModel !== null) {
      changes.locale = this.localeModel as Locale;
    }
    if (this.imageFile !== null) {
      promises.push(
        Storage.put(
          `avatar.${mime.extension(this.imageFile.type)}`,
          this.imageFile,
          {
            level: 'private',
            contentType: this.imageFile.type
          }
        ).then(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (result: Record<string, any>): void => {
            changes.avatar = result.key;
            this.imageFile = null;
          }
        )
      );
    }
    Promise.all(promises)
      .then((): Promise<void> => userStore.updateUser(changes).then(noop))
      .then((): void => {
        this.nameModel = null;
        this.localeModel = null;
        this.avatarModel = null;
        this.$vuetify.lang.current = userStore.locale;
      })
      .catch((): void => {
        this.hasError = true;
      })
      .finally((): void => {
        this.isSaving = false;
      });
  }
}
